@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');


    #Hamburger_Menu {
        display: block;
        justify-content: flex-start;
    }
    .navBar {
        position: relative;
    }
    .navBar button {
        position: fixed;
        left: 25px;
        top: 25px;
        z-index: 10;
        cursor: pointer;
    }
    .menuNav {
        overflow-y: scroll;
        list-style: none;
        position: fixed;
        top: 12%;
        left: 0;
        bottom: 0;
        height: 88vh;
        width: 0;
        overflow: hidden;
        max-width: 290px;
        z-index: 9;
        transition: .75s;
        border-top-right-radius: 2.5em;
    }
    .menuNav.showMenu {
        width: 80%;
    }
    a {
        display: block;
        border: 1px solid transparent;
        padding: 10px 40px;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }
    .menuNav li:first-child {
        margin-top: 3.5rem;
    }
    .navBar button {
        background: transparent;
        border: none;
    }
    #menu_toggle {
        padding: 10px 120px;
    }
    #Stratios {
        padding-top: 25%;
        width:100%;
    }