@import url('https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

* {
    padding: 0;
    margin: 0;
}
#showSmall {
    display: none;
}
.showBig {
    display: block;
}
::-webkit-scrollbar {
    display: none;
}
#background {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
#boldMe {
    font-weight: bold;
}
.skills img{
    height: 55px;
    border-radius: 50%;
    margin: 5px;
}
.page_wrap { 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top:15%;
}
#titles { 
    font-family: 'Orbitron', sans-serif; 
}
#tail_wrap {
    display: flex;
    justify-content: space-evenly;
    bottom: 1%;
}
#fine_print {
    font-size: .75em;
}
h2.pgNum {
    display: block;
    position: absolute;
    right: 12%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.firstNum {
    font-size: 1.5em;
}
#homePgOne {
    bottom: 52%;
}
#homePgTwo {
    bottom: 5%;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (max-width: 720px) {
    #fine_print {
        font-size: .75em;
    }
    .page_wrap {
        padding-top: 50%;
    }
    #showSmall {
        display: block;
    }
    .showBig {
        display: none;
    }
    .pgNum {
        display: none;
    }
}