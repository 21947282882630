#whatWeOffer {
  list-style-type: none;
}
.App {
  text-align: center;
}
#tail_wrap {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 12px;
}
#headspace {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  z-index: 1;
  background-image: url('./static/WHandBomber2.jpeg');
  background-position: 20% -55%;
  overflow: clip;
}
#Logo {
  height: 125px;
}
/* #background > div:nth-child(4) > div:nth-child(1) > div {
  background-color: white !important;
} */
.page_wrap { 
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 4.5%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#divSplit {
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 1%;
}
#divSplit ul li {
  text-align: left;
}
#divSplitContact {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}
#divSplitRecruit {
  display: flex;
  justify-content: space-evenly;
  width:100%;
}
#container-div {
  display: flex;
  justify-content: space-around;
}
#about-div {
  width:65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
#divSplit #whatWeOffer li {
  text-align: center;
  list-style: none;
}
.shipImg {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 570px;
}
div.swiper-wrapper{
  width: 555px;
  border-radius: 7.5px;
  right: 7.25%;
}
#inner-div {
  width:25%;
}
#inner-div div img {
  width: 100px;
}
#royal_blue {
  color: royalblue;
}
#in_game {
  font-style: italic;
  font-size: 1.1em;
}
#portrait {
  margin: 7.5px 7.5px 0px 7.5px;
  border-radius: .25em;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 500px) {
  #divSplit {
    display: flex;
    flex-direction: column;
    width: 85%;
  }
  #divSplit ul li {
    text-align: left;
  }
  #divSplitContact {
    display: flex;
    flex-direction: column;
  }
  #inner-div {
    width: 100%;
    padding-top:5%;
    padding-bottom: 5%;
  }
  #containerDiv {
    width: 100%;
  }
  #about-div {
    width:85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .shipImg {
    width: 300px;
    height: 250px;
  }
  div.swiper-wrapper{
    width: 300px;
    right: 10.5%;
  }
  div.swiper-wrapper img{
    width: 285px;
  }
}
